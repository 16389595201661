




import { Vue, Component } from "vue-property-decorator";

@Component
export default class SupportEmailLink extends Vue {
    email: string = process.env.VUE_APP_APP_SUPPORT_EMAIL;
    supportEmailLink: string =
        "mailto:" + process.env.VUE_APP_APP_SUPPORT_EMAIL;
}
