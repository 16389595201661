


















































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import { mdiNumeric1Circle, mdiNumeric2Circle } from "@mdi/js";
import SupportEmailLink from "@/components/SupportEmailLink.vue";
@Component({
    components: { SupportEmailLink, BackButton },
})
export default class FaqMoneyHasBeenDeductedFromMyAccountButTheTransactionWasNotSuccessful extends Vue {
    oneIcon: string = mdiNumeric1Circle;
    twoIcon: string = mdiNumeric2Circle;
}
